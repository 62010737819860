@keyframes movebounce {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes changedir {
    0% {
        top: 25%;
        right: 18%;
    }
    25% {
        top: 25%;
        right: 28%;
    }
    50% {
        top: 45%;
        right: 28%;
    }
    75% {
        top: 45%;
        right: 18%;
    }
    100% {
        top: 25%;
        right: 18%;
    }
}

@keyframes changedir-rev {
    0% {
        top: 45%;
        right: 28%;
    }
    25% {
        top: 45%;
        right: 18%;
    }
    50% {
        top: 25%;
        right: 18%;
    }
    75% {
        top: 25%;
        right: 28%;
    }
    100% {
        top: 45%;
        right: 28%;
    }
}

@keyframes ripple1 {
    0% {
        transform: scale(5.5);
        opacity: 0.3;
    }
    100% {
        transform: scale(8.5);
        opacity: 0.0;
    }
}

@keyframes ripple2 {
    0% {
        transform: scale(3.5);
    }
    100% {
        transform: scale(5.5);
    }
}
@keyframes ripple3 {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(3.5);
    }
}

@keyframes move-img {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 1360px;
    }
}
@keyframes move-img-reverse {
    0% {
        background-position: 1360px;
    }
    100% {
        background-position: 0;
    }
}

@keyframes movebounce {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes movebounce {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
@-webkit-keyframes moveright {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes moveright {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes rightFadeInOut {
    0% {
        opacity: 1;
        transform: translateX(1000px);
    }
    100% {
        opacity: 1;
    }
}

@keyframes movej {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(0);
    }
    70% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes btndown {
    0% {
        transform: none;
    }
    25% {
        transform: translate(0, -10px);
    }
    50% {
        transform: translate(0, 0);
    }
    75% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, 0);
    }
}
