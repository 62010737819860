.button-primary {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #1F357D;
  padding: 16px 32px;
  gap: 10px;
  background: #fff;
  border: 1px solid #1F357D;
  border-radius: 8px;
  transition: all .3s;
}

.button-primary-parlamentar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #1F357D;
  padding: 16px 32px;
  gap: 10px;
  background: #fff;
  border: 1px solid #1F357D;
  border-radius: 8px;
  transition: all .3s;
}

.button-primary-parlamentar:hover {
  background-color: #1f357d1f;
  border-color: white;
  color: white;
  cursor: pointer;
}

.button-primary i {
  font-size: calc(22px + 3*(100vw - 300px) / 1620);
  color: #1F357D !important;
}

.button-primary:hover {
  background-color: #1f357d1f;
  border-color: white;
  cursor: pointer;
}

.w100 {
  width: 100%;
  display: block;
}

.w100 {
  max-width: 100% !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.g-30 {
  gap: 30px !important;
}

.g-32 {
  gap: 32px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-80 {
  margin-top: 80px;
}

.g-16 {
  gap: 16px;
}

.g-24 {
  gap: 24px;
}

.g-13 {
  gap: 13px;
}

.g-16 {
  gap: 16px;
}

.ph-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.pv-18 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.b-b-16 {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.g-8 {
  gap: 8px;
}

.g-28 {
  gap: 28px;
}

.mt-96 {
  margin-top: 96px;
}

@media (max-width: 768px) {
  .mt-32 {
    margin-top: 23px !important;
  }

  .mt-24 {
    margin-top: 16px !important;
  }

  .mt-64 {
    margin-top: 32px !important;
  }
}

h2 {
  font-size: 1.25rem !important;
}

@media only screen and (max-width: 767px) {
  header.agency nav a img {
    filter: none !important;
    /* Remove o filtro */
  }
}

.barra-lateral-noticias {
  width: 100%;
  padding: 16px 16px 31px;
  border-radius: 16px;
  border: 1px solid #c6d3d9;
  background-color: #fff;
}

.entry-content {
  padding: 1.5em 1.5em 0;
}

.flex {
  display: flex;
}

.content-post>*+* {
  margin-top: 1.6rem;
}

.flex-col {
  gap: 32px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    .ao-vivo {
      display: flex;
      flex-direction: column;
      height: 100%;

      .context {
        flex: 1 1 auto;
      }

      .jmvplayer {
        flex: 1;
      }

      h3 {
        font-size: 1rem !important;
        margin: 0 0 8px 0 !important;
        color: #000 !important;
      }

      a {
        color: #1f357d;
        text-decoration: none;
        font-weight: bold;
      }

    }


    .ao-vivo h3 {
      font-size: 1rem !important;
      margin: 0 0 8px 0 !important;
      color: #000 !important;
    }



  }
}

.border-r-w {
  border: 1px solid #C6D3D9;
  border-radius: 16px;
}

//Typography Classes
//Headlines - Desktop
.desktop-headeline-1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 6.2rem;
  color: #212121;

}

.desktop-headeline-2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 4.4rem;
  color: #212121;
}

.desktop-headeline-3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.6rem;
  color: #212121;
}

.desktop-headeline-4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #212121;
}

.desktop-headeline-5 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #212121;
}



/***************************************************/

//Body - Desktop
.desktop-body-1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #212121;
}

.desktop-body-2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #212121;
}

.desktop-body-3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #212121;
}

//Body - Mobile

/***************************************************/

//Button/Link - Desktop
.desktop-button-link-normal {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #212121;
}

.desktop-button-link-medium {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #212121;
}

.desktop-button-link-small {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #212121;
}

.desktop-button-link-small-link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-decoration-line: underline;
  color: #212121;
}

//Button/Link - Mobile
.mobile-button-link-normal {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #212121;
}

.mobile-button-link-medium {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #212121;
}

.mobile-button-link-small {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #212121;
}

.mobile-button-link-small-link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-decoration-line: underline;
  color: #212121;
}

/***************************************************/

//Fields - Desktop
.desktop-fields-text-1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #212121;
}

.desktop-fields-text-2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #212121;
}

//Fields - Mobile
.mobile-fields-text-1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #212121;
}

.mobile-fields-text-2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #212121;
}

.color-white {
  color: white;
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .desktop-headeline-5 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.0rem;
  }

  .desktop-headeline-4 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.0rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  body {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}




//Headlines - Mobile
@media (max-width: 768px) {
  .mobile-headeline-1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.6rem;
    color: #212121;
  }

  .mobile-headeline-2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.8rem;
    color: #212121;
  }

  .mobile-headeline-3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #212121;
  }

  .mobile-headeline-4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #212121;
  }

  .mobile-headeline-5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #212121;
  }


  .mobile-body-1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #212121;
  }

  .mobile-body-2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #212121;
  }

  .mobile-body-3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #212121;

  }

}

.row-capa-cargos {
  font-family: "Arial-BoldMT", "sans-serif";
  margin: 20px 0
}

.row-capa-cargos .container-cargos {
  margin-top: 10px
}

.row-capa-cargos .container-cargos .row-cargos .margin-top {
  margin-top: 20px
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos {
  padding-top: 5px;
  padding-bottom: 5px
}

@media only screen and (max-width: 767px) {
  .row-capa-cargos .container-cargos .row-cargos .col-box-cargos {
    padding-top: 10px;
    padding-bottom: 10px
  }
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .col-box-item {
  padding-top: 29px;
  padding-bottom: 28px;
  background-color: #1B3455;
  border-radius: 12px
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .col-box-item a {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #021E43
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade {
  justify-content: center
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .border-padrao {
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .border-none {
  border: none
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item {
  display: flex;
  justify-content: center;
  flex: auto;
  cursor: pointer;
  padding: 0;
  margin: 5px
}

@media only screen and (min-width: 2560px) {
  .row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item {
    max-height: none
  }
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item .cargos-img {
  border-radius: 8px;
  margin-bottom: 10px;
  width: 166px;
  height: 204px;
  display: flex;
  margin-left: auto;
  margin-right: auto
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item .nome,
.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item .cargo {
  font-size: .8rem;
  text-align: center;
  margin-bottom: 0;
  color: black
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item .cargo {
  font-family: "ArialMT", "sans-serif";
  color: #495057 !important
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item a:hover {
  text-decoration-color: black
}

.row-capa-cargos .container-cargos .row-cargos .col-box-cargos .col-box-itens .nav-acessibilidade .nav-item a:hover .cargo {
  color: black !important
}

.tv-radio {
  background: #1f357d1f;
  padding: 112px 0;
}

footer.footer2.agency .link-horizontal ul li.borders-right {
  border-right: 3px solid #1F357D !important;
  height: 30px;
  margin-right: 15px;
  opacity: 0.051;
}

footer.footer2.agency .brand-container {
  margin-top: 50px;
  padding: 30px;
  background: white !important;
  border-radius: 20px;

  img {
    width: 150px !important;
  }
}

.desktop-body-1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: black;
}

.desktop-body-2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: black;
}

.desktop-body-3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: black;
}